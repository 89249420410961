import useThirdPartyCookieCheck from "~/hooks/useThirdPartyCookies";
import { ReactNode } from "react";
import CenterLoading from "./CenterLoading";
import { TestBoxNotEnabled } from "./util/TestBoxNotEnabled";

interface Props {
    children: ReactNode;
}

export default function PreventIncognito({ children }: Props) {
    const cookiesEnabled = useThirdPartyCookieCheck();

    if (typeof cookiesEnabled === "undefined") {
        return <CenterLoading />;
    }

    if (cookiesEnabled) {
        return <>{children}</>;
    }

    return (
        <TestBoxNotEnabled text="TestBox requires third-party cookies for an optimal experience. Please enable third-party cookies and access in non-incognito mode to continue." />
    );
}
