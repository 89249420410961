import { AxiosError, AxiosResponse } from "axios";
import { useEffect } from "react";
import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "react-query";
import axiosClient from "../etc/axiosClient";
import {
    DISABLE_GOOGLE_ANALYTICS,
    useGoogleAnalytics,
} from "./googleAnalyticsHooks";
import { useAuth, useUpdateAuth } from "~/state/auth";
import { ProductDistribution } from "./useProducts";
import { isAxiosError } from "~/etc/queryClient";
import _ from "lodash";
import { TEAM_KEY } from "~/data/teams";

import { useResetApp } from "~/state/app";
import { LEADS_KEY } from "./useLeads";
import { Environment } from "~/state/env";
import { Workspace } from "./useWorkspace";
import { cleanEmail } from "~/etc/stringHelpers";
import { CompanyRoles } from "~/components/settings/tabs/AccountSettingsUserTab/Cards/components/CompanyRoleAutocomplete";
import { useFlags } from "~/components/LaunchDarkly";

declare global {
    interface Window {
        ENV?: Partial<Environment>;
        Metadata: {
            pixel?: {
                sendData: (data, callback?) => void;
            };
            siteScript?: {
                sendData: (data, callback?) => void;
            };
        };
    }
}
export interface Salesperson {
    salesperson_id: string;
    product_distributions: ProductDistribution[];
    crm_provider?: string;
    email_notifications_requested?: boolean;
}

export interface SalespersonListItem extends Salesperson {
    user: User;
}

export interface MarketingQuestionnaire {
    company_size?: string;
    current_solution?: string;
    buying_timeline?: string;
    use_cases?: string[];
    other_solution?: string;
}

interface IMarketingQuestionnaireRecord {
    [key: string]: MarketingQuestionnaire;
}

export interface ExtraInfo {
    roadmap_products?: string[];
    marketing_questionnaire?: IMarketingQuestionnaireRecord;
    product_tour_completed?: boolean;
    demo_tour_completed?: boolean;
    capture_tour_completed?: boolean;
    capture_demo_request?: string[];
    capture_welcome_modal?: string[];
    send_verification_email?: boolean;
}

export interface Company {
    company_id: string;
    name: string;
    is_testbox_sales_distributor: boolean;
    has_salesforce: boolean;
    subdomain: string;
    invites_allowed: boolean;
    workspaces: Workspace[];
    disable_credential_updates: boolean;
    enable_self_serve_demo_environments: boolean;
    enable_partner_sellers: boolean;
    purchased_licenses: number;
}

export interface User {
    last_accessed: string | null;
    company: Company;
    company_name: string;
    user_id: string;
    first_name: string;
    last_name: string;
    company_role: CompanyRoles;
    avatar_url: string;
    email: string;
    extension_installed: boolean;
    noframing_extension_last_connected_at: string;
    intro_call_scheduled: boolean;
    is_tbx_sales: boolean;
    is_salesperson: boolean;
    is_lead: boolean;
    first_user: boolean;
    is_company_admin: boolean;
    salesperson?: Salesperson;
    extra_info?: ExtraInfo;
    can_use_app: boolean;
    in_grace_period: boolean;
    verified: boolean;
    lead_source?: string;
    is_capture_only?: boolean;
    disable_credential_updates?: boolean;
    has_convert_workspace?: boolean;
    current_workspace?: Workspace;
    is_active?: boolean;
    deactivated_at?: string;
    workspaces: string[];
}

export interface Partner extends User {
    partner_company_name: string;
}

export const USER_API = "/user/";
export const USER_EXTRA_INFO_API = "/user/extra_info/";
export const USER_UPDATE_WORKSPACE_API = "/user/update_workspace/";
export const USER_CHECK_COMPANY_SSO_RETURN_URL =
    "/user/check_company_sso_return_url/";
export const USER_KEY = "user";
export const USER_SSO_RETURN_KEY = "user_sso_return_url";
export const ROLE_API = "/role/";
export const ROLE_KEY = "role";

export default function useUser() {
    const { data: userFromAppState, loading: authLoading } = useAuth();
    const updateAuth = useUpdateAuth();
    const { flags } = useFlags();

    const resetApp = useResetApp();

    const { data: userFromQuery, ...rest } = useQuery<User, AxiosError>(
        USER_KEY,
        async () => {
            try {
                if (flags.enableUserSandboxQueryParameter) {
                    const pathparts = window.location.pathname.split("/");
                    const sandboxId =
                        pathparts[1] === "sandbox" ? pathparts[2] : "";
                    const sandboxParam = sandboxId
                        ? `?sandbox=${sandboxId}`
                        : "";
                    const results = await axiosClient.get(
                        `${USER_API}${sandboxParam}`
                    );
                    return results.data;
                } else {
                    const results = await axiosClient.get(USER_API);
                    return results.data;
                }
            } catch (e) {
                console.log(e);
                return undefined;
            }
        },
        {
            refetchOnWindowFocus: "always",
            onError: (err) => {
                if (
                    isAxiosError(err) &&
                    (err.response?.status === 403 ||
                        err.response?.status === 401)
                ) {
                    updateAuth({
                        loading: false,
                        loggedIn: false,
                        data: undefined,
                    });
                }
            },
        }
    );
    const queryIsLoading = rest.isLoading;

    useEffect(() => {
        if (!userFromQuery) {
            if (userFromAppState || (authLoading && !queryIsLoading)) {
                // logged out
                updateAuth({
                    loggedIn: false,
                    loading: false,
                    data: undefined,
                });
                resetApp();
            }
        } else if (!_.isEqual(userFromQuery, userFromAppState)) {
            if (!userFromAppState) {
                // user logged in
                updateAuth({
                    loggedIn: true,
                    loading: false,
                    data: userFromQuery,
                });
            } else {
                // user has been updated
                updateAuth({
                    loggedIn: true,
                    loading: false,
                    data: userFromQuery,
                });
            }
        }
    }, [userFromQuery, userFromAppState, authLoading, queryIsLoading]);

    const googleAnalytics = useGoogleAnalytics();

    useEffect(() => {
        if (userFromQuery?.email.endsWith("testbox.com")) {
            localStorage.setItem(DISABLE_GOOGLE_ANALYTICS, "1");
        }

        if (googleAnalytics && userFromQuery) {
            googleAnalytics.gtag("set", "user_id", userFromQuery.user_id);
        }
    }, [userFromQuery?.user_id, googleAnalytics]);

    return { data: userFromQuery, ...rest };
}

export const useUpdateUserMutation = (options?: UseMutationOptions) => {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, Partial<User>>(
        USER_KEY,
        async (data) => {
            const results = await axiosClient.patch(USER_API, data);
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            retry: false,
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(USER_KEY);

                if (options?.onSuccess) {
                    options.onSuccess(response, variables as any, context);
                }
            },
            onError: (error, variables, context) => {
                if (isAxiosError(error) && _.isObject(error.response?.data)) {
                    options?.onError?.(error, variables as any, context);
                } else {
                    console.error("Something went wrong");
                }
            },
        }
    );
};

export const useUpdateUserExtraInfoMutation = (
    options?: UseMutationOptions
) => {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, ExtraInfo>(
        USER_KEY,
        async (extra_info) => {
            const results = await axiosClient.patch(USER_EXTRA_INFO_API, {
                extra_info,
            });
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            retry: false,
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(USER_KEY);

                if (options?.onSuccess) {
                    options.onSuccess(response, variables as any, context);
                }
            },
            onError: (error, variables, context) => {
                if (isAxiosError(error) && _.isObject(error.response?.data)) {
                    options?.onError?.(error, variables as any, context);
                } else {
                    console.error("Something went wrong");
                }
            },
        }
    );
};

export interface EditWorkspaceMutation {
    workspace: string;
}

export const useUpdateUserWorkspaceMutation = (
    options?: UseMutationOptions
) => {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, EditWorkspaceMutation>(
        USER_KEY,
        async (data) => {
            const results = await axiosClient.patch(
                USER_UPDATE_WORKSPACE_API,
                data
            );
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            retry: false,
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(USER_KEY);

                if (options?.onSuccess) {
                    options.onSuccess(response, variables as any, context);
                }
            },
            onError: (error, variables, context) => {
                if (isAxiosError(error) && _.isObject(error.response?.data)) {
                    options?.onError?.(error, variables as any, context);
                } else {
                    console.error("Something went wrong");
                }
            },
        }
    );
};

export interface EditRoleMutation {
    company_role: string;
}

export const useUpdateUserRoleMutation = (
    userId: string,
    options?: UseMutationOptions
) => {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, EditRoleMutation>(
        [ROLE_KEY, userId],
        async (data) => {
            const results = await axiosClient.patch(`${ROLE_API}${userId}/`, {
                company_role: data.company_role,
            });
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries([TEAM_KEY]);
                client.invalidateQueries(LEADS_KEY);

                if (options?.onSuccess) {
                    options.onSuccess(response, variables as any, context);
                }
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
};

interface UserCompanySSOReturnUrl {
    sso_return_url?: string;
}

export const useGetUserCompanySSOReturnUrl = (
    email?: string | null,
    options?: UseQueryOptions<UserCompanySSOReturnUrl, AxiosError>
) => {
    return useQuery<UserCompanySSOReturnUrl, AxiosError>(
        [USER_SSO_RETURN_KEY, email],
        () =>
            axiosClient
                .get<UserCompanySSOReturnUrl>(
                    `${USER_CHECK_COMPANY_SSO_RETURN_URL}?email=${encodeURIComponent(
                        cleanEmail(email)
                    )}`
                )
                .then((response) => response.data),
        {
            ...options,
            retry: false,
            enabled: false, // don't fetch by default only on `refetch` call
        }
    );
};
