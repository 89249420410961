import { ButtonProps } from "@mui/material";
import { atom, useRecoilState } from "recoil";
import { v4 } from "uuid";

type DataAttributes = Record<`data-${string}`, string>;

export type ConfirmationActionButton<TActionId extends string = string> =
    DataAttributes &
        Omit<ButtonProps, "onClick"> & {
            id: TActionId;
            text: string;
            onBeforeResolving?: () =>
                | TActionId
                | void
                | Promise<TActionId | void>;
        };

export const DEFAULT_DISMISS_CONFIRMATION_ACTION = "dismiss";
type DefaultDismissConfirmationAction =
    typeof DEFAULT_DISMISS_CONFIRMATION_ACTION;

export interface ConfirmationConfig<TActionId extends string = string> {
    text: string;
    title: string;
    actions: ConfirmationActionButton<TActionId>[];
    dismissAction?: TActionId;

    meta: {
        id: string;
        promiseResolve: (value: TActionId | PromiseLike<TActionId>) => void;
    };
}

interface ConfirmationState {
    confirmationStack: ConfirmationConfig[];
}

export const autoConfirmationDialogState = atom<ConfirmationState>({
    key: "auto-confirmation-dialog",
    default: {
        confirmationStack: [],
    },
});

export const useConfirmation = () => {
    const [state, setState] = useRecoilState(autoConfirmationDialogState);

    return {
        ...state,
        confirm<TActionId extends string>(
            newConfirmation: Omit<ConfirmationConfig<TActionId>, "id" | "meta">
        ) {
            return new Promise<TActionId | DefaultDismissConfirmationAction>(
                (resolve) => {
                    setState((state) => ({
                        ...state,
                        confirmationStack: [
                            ...state.confirmationStack,
                            {
                                meta: {
                                    id: v4(),
                                    promiseResolve: resolve as (
                                        value: string | PromiseLike<string>
                                    ) => void,
                                },
                                ...newConfirmation,
                                actions:
                                    newConfirmation.actions as unknown as ConfirmationActionButton<string>[],
                            },
                        ],
                    }));
                }
            );
        },
        dismissConfirmation(
            confirmationIdToDismiss: ConfirmationConfig["meta"]["id"]
        ) {
            setState((state) => ({
                ...state,
                confirmationStack: state.confirmationStack.filter(
                    (confirmation) =>
                        confirmation.meta.id !== confirmationIdToDismiss
                ),
            }));
        },
    };
};
