import { ThemeOptions } from "@mui/material/styles/createTheme";
import { alpha, createTheme } from "@mui/material/styles";
import React from "react";

const greenBackground = "#F2F8F8";
const primaryBase = "rgb(198, 58, 221)";
const secondaryBase = "rgb(35, 205, 155)";

const fontFamily = {
    text: ["Inter", "sans-serif"].join(","),
    heading: ["GT Walsheim Pro Medium", "sans-serif"].join(","),
};

const fontWeight = {
    heading: 500,
    text: 400,
};

declare module "@mui/material/styles" {
    interface Palette {
        componentsAlertWarningBackground: Palette["primary"];
        componentsAlertWarningColor: Palette["primary"];
        tooltip: {
            background: string;
            contrastText: string;
        };
    }

    interface TypeBackground {
        default: string;
        paper: string;
        light: string;
    }

    interface PaletteOptions {
        componentsAlertWarningBackground?: PaletteOptions["primary"];
        componentsAlertWarningColor?: PaletteOptions["primary"];
        tooltip?: {
            background: string;
            contrastText: string;
        };
    }

    interface PaletteColor {
        selected?: string;
        selectedDark?: string;
        backgroundLight?: string;
        hover?: string;
        focusVisible?: string;
    }

    interface SimplePaletteColorOptions {
        selected?: string;
        selectedDark?: string;
        hover?: string;
        focus?: string;
        focusVisible?: string;
        outlinedBorder?: string;
        backgroundLight?: string;
    }

    interface TypographyVariants {
        componentsAlertWarningColor: React.CSSProperties;
        tableHeader: React.CSSProperties;
        tooltipLabel: React.CSSProperties;
    }

    // Allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        componentsAlertWarningColor?: React.CSSProperties;
        tableHeader: React.CSSProperties;
        tooltipLabel: React.CSSProperties;
    }

    export interface TypeText {
        primary: string;
        secondary: string;
        disabled: string;
        outlinedBorder: string;
        hover: string;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        componentsAlertWarningBackground: true;
        componentsAlertWarningColor: true;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        componentsAlertWarningColor: true;
        tableHeader: true;
    }
}

const themeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        background: {
            default: greenBackground,
            light: "rgba(242, 248, 248, 1)",
        },
        primary: {
            main: "rgba(198, 58, 221, 1)",
            dark: "rgba(138, 40, 154, 1)",
            light: "rgba(209, 97, 227, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            hover: "rgba(198, 58, 221, 0.06)",
            selected: alpha(primaryBase, 0.08),
            selectedDark: alpha(primaryBase, 0.12),
            focus: "rgba(198, 58, 221, 0.16)",
            focusVisible: "rgba(198, 58, 221, 0.4)",
            outlinedBorder: "rgba(198, 58, 221, 0.6)",
        },
        secondary: {
            main: "rgba(35, 205, 155, 1)",
            dark: "rgba(24, 143, 108, 1)",
            light: "rgba(79, 215, 175, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            selected: alpha(secondaryBase, 0.08),
            focusVisible: alpha(secondaryBase, 0.3),
        },
        error: {
            main: "rgba(229, 57, 53, 1)",
            dark: "rgba(198, 40, 40, 1)",
            light: "rgba(239, 83, 80, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            backgroundLight: "rgba(229, 57, 53, 0.12)",
        },
        warning: {
            main: "rgba(245, 124, 0, 1)",
            dark: "rgba(239, 108, 0, 1)",
            light: "rgba(255, 152, 0, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            backgroundLight: "rgba(245, 124, 0, 0.12)",
        },
        info: {
            main: "rgba(2, 136, 209, 1)",
            dark: "rgba(1, 87, 155, 1)",
            light: "rgba(3, 169, 244, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            backgroundLight: "rgba(2, 136, 209, 0.12)",
        },
        success: {
            main: "rgba(67, 160, 71, 1)",
            dark: "rgba(27, 94, 32, 1)",
            light: "rgba(76, 175, 80, 1)",
            contrastText: "rgba(255, 255, 255, 1)",
            backgroundLight: "rgba(67, 160, 71, 0.12)",
        },
        text: {
            primary: "rgba(6, 33, 6, 1)",
            secondary: "rgba(6, 33, 6, 0.72)",
            disabled: "rgba(6, 33, 6, 0.4)",
            outlinedBorder: "rgba(6, 33, 6, 0.16)",
            hover: "rgba(6, 33, 6, 0.04)",
        },
        divider: "rgba(0, 0, 0, 0.12)",
        tooltip: {
            background: "rgba(6, 33, 6, 0.9)",
            contrastText: "rgba(255, 255, 255, 1)",
        },
        action: {
            active: "rgba(6, 33, 6, 0.56)",
            hover: "rgba(6, 33, 6, 0.04)",
            hoverOpacity: 0.06,
            selected: "rgba(6, 33, 6, 0.08)",
            selectedOpacity: 0.14,
            disabled: "rgba(6, 33, 6, 0.38)",
            disabledBackground: "rgba(6, 33, 6, 0.12)",
            focus: "rgba(6, 33, 6, 0.12)",
            focusOpacity: 0.16,
        },
        componentsAlertWarningBackground: {
            main: "#FFF4E5",
        },
        componentsAlertWarningColor: {
            main: "#663C00",
        },
        common: {
            black: "rgba(0, 0, 0, 1)",
            white: "rgba(255, 255, 255, 1",
        },
    },
    typography: {
        button: {
            textTransform: "none",
        },
        fontFamily: fontFamily.text,
        h1: {
            fontFamily: fontFamily.heading,
            fontSize: "96px",
            fontWeight: fontWeight.heading,
            lineHeight: "112.03px",
            letterSpacing: "-1.5px",
        },
        h2: {
            fontFamily: fontFamily.heading,
            fontSize: "60px",
            fontWeight: fontWeight.heading,
            lineHeight: "72px",
            letterSpacing: "-0.5px",
        },
        h3: {
            fontFamily: fontFamily.heading,
            fontSize: "48px",
            fontWeight: fontWeight.heading,
            lineHeight: "56.02px",
        },
        h4: {
            fontFamily: fontFamily.heading,
            fontSize: "34px",
            fontWeight: fontWeight.heading,
            lineHeight: "41.99px",
            letterSpacing: "0.25px",
        },
        h5: {
            fontFamily: fontFamily.heading,
            fontSize: "24px",
            fontWeight: fontWeight.heading,
            lineHeight: "32.02px",
        },
        h6: {
            fontFamily: fontFamily.heading,
            fontSize: "20px",
            fontWeight: fontWeight.heading,
            lineHeight: "32px",
            letterSpacing: "0.15px",
        },
        componentsAlertWarningColor: {
            color: "#663C00",
        },
        subtitle1: {
            fontFamily: fontFamily.text,
            fontSize: "16px",
            fontWeight: fontWeight.heading,
            lineHeight: "28px",
            letterSpacing: "0.15px",
        },
        subtitle2: {
            fontFamily: fontFamily.text,
            fontSize: "14px",
            fontWeight: fontWeight.heading,
            lineHeight: "21.98px",
            letterSpacing: "0.1px",
        },
        body1: {
            fontFamily: fontFamily.text,
            fontSize: "16px",
            fontWeight: fontWeight.text,
            lineHeight: "24px",
            letterSpacing: "0.15px",
        },
        body2: {
            fontFamily: fontFamily.text,
            fontSize: "14px",
            fontWeight: fontWeight.text,
            lineHeight: "20.02px",
            letterSpacing: "0.17px",
        },
        caption: {
            fontFamily: fontFamily.text,
            fontSize: "12px",
            fontWeight: fontWeight.text,
            lineHeight: "19.92px",
            letterSpacing: "0.4px",
        },
        overline: {
            fontFamily: fontFamily.text,
            fontSize: "12px",
            fontWeight: fontWeight.text,
            lineHeight: "31.92px",
            letterSpacing: "1px",
            textTransform: "uppercase",
        },
        tableHeader: {
            fontFamily: fontFamily.text,
            fontSize: "14px",
            fontWeight: fontWeight.heading,
            lineHeight: "24px",
            letterSpacing: "0.17px",
        },
        tooltipLabel: {
            fontFamily: fontFamily.text,
            fontSize: "10px",
            fontWeight: fontWeight.heading,
            lineHeight: "14px",
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    paddingTop: "3px",
                    paddingBottom: "3px",
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: theme.palette.secondary.selected,
                    },
                }),
            },
        },
    },
};

const theme = createTheme(themeOptions);

export default theme;
