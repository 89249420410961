import useV2ExtensionInstalled from "~/hooks/useV2ExtensionInstalled";
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { checkIsChrome } from "~/etc/isChrome";
import clsx from "clsx";

interface Props {
    children: ReactNode;
}

const BrowserCapabilityContext = createContext<{
    extensionInstalled: boolean;
    loading: boolean;
    isChrome: boolean;
}>({
    extensionInstalled: false,
    loading: true,
    isChrome: true,
});

export function useBrowserCapability() {
    return useContext(BrowserCapabilityContext);
}

export function BrowserCapabilityContextProvider({ children }: Props) {
    const isExtensionInstalled = useV2ExtensionInstalled();
    const [extensionInstalled, setExtensionInstalled] = useState(false);
    const [loading, setLoading] = useState(true);
    const isChrome = checkIsChrome();

    // Wait 3 seconds. If we haven't heard from the extension, it's
    // probably not installed.
    useEffect(() => {
        console.log("Starting to look for extension...");
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    // Listen for the extension to be installed.
    useEffect(() => {
        if (loading && isExtensionInstalled === null) {
            return;
        }
        setExtensionInstalled(!!isExtensionInstalled);
        setLoading(false);
    }, [isExtensionInstalled, setExtensionInstalled, loading, setLoading]);

    return (
        <BrowserCapabilityContext.Provider
            value={{ extensionInstalled, loading, isChrome }}
        >
            <span
                className={clsx({
                    "spec-extension-installed": extensionInstalled,
                    "spec-extension-loading": loading,
                })}
            />
            {children}
        </BrowserCapabilityContext.Provider>
    );
}
