import {
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
} from "react";
import { useUpdateUserMutation } from "~/hooks/useUser";
import { useAuth } from "~/state/auth";
import { noFrameSendMessage } from "./NoFrameSendMessage";
import { asError } from "catch-unknown";

const EXTENSION_ID = "nklgajbllblebcjflehaifaooeiakgnm";

export type NoFrameExtensionContextType = {
    connected: boolean;
    extensionId: string;
    port: chrome.runtime.Port | undefined;
    error: Error | undefined;
};

const NoFrameExtensionContext = createContext<NoFrameExtensionContextType>(
    null!!!
);

export const useNoFrameExtension = () => {
    return useContext(NoFrameExtensionContext);
};

export const NoFrameExtensionProvider = (props: PropsWithChildren<{}>) => {
    const [connected, setConnected] = useState(false);
    const [port, setPort] = useState<chrome.runtime.Port | undefined>();
    const [error, setError] = useState<Error | undefined>();
    const { loggedIn } = useAuth();
    const { mutate: updateUser } = useUpdateUserMutation();

    useEffect(() => {
        let port: chrome.runtime.Port | undefined;

        async function attemptConnection() {
            if (!loggedIn) {
                // not logged in yet, don't try to connect
                return;
            }

            try {
                port = chrome.runtime.connect(EXTENSION_ID);
                const { connection_success } = await noFrameSendMessage(
                    EXTENSION_ID,
                    "connect-to-extension",
                    { tbx_payload: {} }
                );

                if (connection_success) {
                    console.log("connected to extension", EXTENSION_ID);
                    updateUser({
                        noframing_extension_last_connected_at:
                            new Date().toISOString(),
                    });
                    setPort(port);
                    setConnected(true);
                } else {
                    throw new Error("noFrame extension not found!");
                }
            } catch (e) {
                console.error("failed to connect to noFrame extension", e);
                setPort(undefined);
                setConnected(false);
                setError(asError(e));
            }
        }

        attemptConnection();

        return () => {
            if (port) {
                port.disconnect();
                console.log("disconnected from extension");
            }
        };
    }, [updateUser, loggedIn]);

    return (
        <NoFrameExtensionContext.Provider
            value={{
                extensionId: EXTENSION_ID,
                connected,
                port,
                error,
            }}
        >
            {props.children}
        </NoFrameExtensionContext.Provider>
    );
};
