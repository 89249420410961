import axios from "axios";
import { useEffect, useState } from "react";

export const useThirdPartyCookieCheck = () => {
    const [isSupported, setIsSupported] = useState<boolean | undefined>(
        undefined
    );

    useEffect(() => {
        axios
            .get("https://chambits.github.io/create-third-party-cookie/")
            .catch((e) => {
                console.log(
                    "Unable to create third-party cookie, proceeding..."
                );
                setIsSupported(true);
            })
            .then(() => {
                const frame = document.createElement("iframe");
                frame.id = "3pc";
                frame.src =
                    "https://chambits.github.io/create-third-party-cookie/"; // Add your hosted domain url here
                frame.style.display = "none";
                frame.style.position = "fixed";
                document.body.appendChild(frame);

                window.addEventListener(
                    "message",
                    function listen(event) {
                        if (
                            event.data === "3pcSupported" ||
                            event.data === "3pcUnsupported"
                        ) {
                            setIsSupported(event.data === "3pcSupported");
                            document.body.removeChild(frame);
                            window.removeEventListener("message", listen);
                        }
                    },
                    false
                );
            });
    }, []);

    return isSupported;
};

export default useThirdPartyCookieCheck;
