import {
    ConfirmationActionButton,
    ConfirmationConfig,
    DEFAULT_DISMISS_CONFIRMATION_ACTION,
} from "./confirmation.state";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useState } from "react";

interface GenericConfirmationDialogProps {
    dialogConfig: ConfirmationConfig;
    onSubmit: (action?: ConfirmationActionButton) => any;
}

const unknownAction: ConfirmationActionButton = {
    id: "unknown",
    text: "No action setup",
};

export function GenericConfirmationDialog({
    dialogConfig,
    onSubmit,
}: GenericConfirmationDialogProps) {
    const [open, setOpen] = useState(true);

    const handleCloseAndResolve = async (action: ConfirmationActionButton) => {
        const actualActionToResolveTo = await action.onBeforeResolving?.();
        dialogConfig.meta.promiseResolve(actualActionToResolveTo ?? action.id);
        setOpen(false);
        onSubmit(action);
    };

    const handleActivateDismissAction = () => {
        const { dismissAction } = dialogConfig;
        if (dismissAction) {
            const action = dialogConfig.actions.find(
                (action) => action.id === dismissAction
            );
            if (action) {
                handleCloseAndResolve(action);
                return;
            } else {
                console.error(`${action} action was not found`);
            }
        }

        dialogConfig.meta.promiseResolve(DEFAULT_DISMISS_CONFIRMATION_ACTION);
        setOpen(false);
        onSubmit();
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            onClose={() => handleActivateDismissAction()}
        >
            <DialogTitle>{dialogConfig.title}</DialogTitle>
            <DialogContent>{dialogConfig.text}</DialogContent>
            <DialogActions>
                {(dialogConfig.actions ?? [unknownAction]).map(
                    (buttonConfig) => {
                        const {
                            text,
                            onBeforeResolving: _,
                            ...buttonProps
                        } = buttonConfig;

                        return (
                            <Button
                                key={buttonConfig.id}
                                {...buttonProps}
                                onClick={() =>
                                    handleCloseAndResolve(buttonConfig)
                                }
                            >
                                {text}
                            </Button>
                        );
                    }
                )}
            </DialogActions>
        </Dialog>
    );
}
