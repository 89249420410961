import { AxiosError, AxiosResponse } from "axios";
import axiosClient from "~/etc/axiosClient";
import { cleanEmail } from "~/etc/stringHelpers";
import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
} from "react-query";
import { Partner, User, USER_KEY } from "~/hooks/useUser";

export const TEAM_KEY = "team";

interface UseTeamParams {
    partners?: boolean;
}

export function useTeam() {
    return useQuery(
        TEAM_KEY,
        async () => {
            const results = await axiosClient.get<User[]>(`/team/`);
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            staleTime: 0,
        }
    );
}

export function useTeamPartners(params?: UseTeamParams) {
    const { partners } = params ?? {};

    return useQuery(
        [TEAM_KEY, "partners"],
        async () => {
            const results = await axiosClient.get<Partner[]>(`/team/`, {
                params: { partners },
            });
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            staleTime: 0,
        }
    );
}

interface useDeleteTeamUserVariables {
    userId: string;
}

export function useDeleteTeamUserMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, useDeleteTeamUserVariables>(
        ({ userId }) => {
            return axiosClient.request({
                url: `/user/${userId}`,
                method: "DELETE",
            });
        },
        {
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(TEAM_KEY);
                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}

interface DeactivateTeamMemberMutation {
    userId: string;
}

export function useDeactivateTeamMemberMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, DeactivateTeamMemberMutation>(
        ({ userId }) => {
            return axiosClient.patch(`/user/${userId}`, {
                is_active: false,
                deactivated_at: new Date().toISOString(),
            });
        },
        {
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(TEAM_KEY);
                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) => {
                options?.onError?.(error, variables as any, context);
            },
        }
    );
}

interface ActivateTeamMemberMutation {
    userId: string;
}

export function useActivateTeamMemberMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, ActivateTeamMemberMutation>(
        ({ userId }) => {
            return axiosClient.patch(`user/${userId}`, {
                is_active: true,
                deactivated_at: null,
            });
        },
        {
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(TEAM_KEY);
                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) => {
                options?.onError?.(error, variables as any, context);
            },
        }
    );
}

interface UpdateTeamMemberMutation {
    user_id: string;
    email: string;
    first_name: string;
    last_name: string;
    company_role: string;
    workspaces: string[];
}

export function useUpdateTeamMemberMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, UpdateTeamMemberMutation>(
        ({
            user_id,
            email,
            first_name,
            last_name,
            company_role,
            workspaces,
        }) => {
            return axiosClient.patch(`user/${user_id}`, {
                user_id,
                email,
                first_name,
                last_name,
                company_role,
                workspaces,
            });
        },
        {
            onSuccess: (response, variables, context) => {
                client.invalidateQueries(TEAM_KEY);
                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) => {
                options?.onError?.(error, variables as any, context);
            },
        }
    );
}

export interface InviteUserMutation {
    email: string;
    company_role: string;
    first_name: string;
    last_name: string;
    // workspaces should be made required when transition to v2 is complete
    workspaces?: string[];
    company?: string;
    partner_company_name?: string;
}

export function useInviteUserMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, InviteUserMutation>(
        async (data) => {
            const results = await axiosClient.post(`/invite/`, {
                ...data,
                email: cleanEmail(data.email),
            });
            if (results.status === 200 || results.status === 201) {
                return results.data;
            }
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries(USER_KEY);
                client.invalidateQueries(TEAM_KEY);

                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}

export interface BulkInviteUserMutation {
    emails: string[];
    company_role: string;
    // workspaces should be made required when transition to v2 is complete
    workspaces?: string[];
}

export function useBulkInviteUserMutation(options?: UseMutationOptions) {
    const client = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, BulkInviteUserMutation>(
        async (data) => {
            const results = await axiosClient.post(`/invite/bulk_create`, data);
            if (results.status === 200) {
                return results.data;
            }
        },
        {
            onSuccess: async (response, variables, context) => {
                client.invalidateQueries(TEAM_KEY);
                options?.onSuccess?.(response, variables as any, context);
            },
            onError: (error, variables, context) =>
                options?.onError?.(error, variables as any, context),
        }
    );
}
