import { ThemeProvider } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import theme from "../theme";
import { GenericConfirmationDialog } from "./GenericConfirmationDialog";
import { useConfirmation } from "./confirmation.state";

export function ConfirmationProvider({ children }: PropsWithChildren<unknown>) {
    const { confirmationStack, dismissConfirmation } = useConfirmation();

    return (
        <>
            <ThemeProvider theme={theme}>
                {confirmationStack.map((confirmationConfig) => (
                    <GenericConfirmationDialog
                        key={confirmationConfig.meta.id}
                        dialogConfig={confirmationConfig}
                        onSubmit={() => {
                            setTimeout(() => {
                                // Wait for the end of the dialog animation
                                dismissConfirmation(confirmationConfig.meta.id);
                            }, 500);
                        }}
                    />
                ))}
            </ThemeProvider>

            {children}
        </>
    );
}
