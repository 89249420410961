import CircularProgress, {
    CircularProgressProps,
} from "@mui/material/CircularProgress";
import { ReactNode } from "react";
import { Flex } from "@testboxlab/design-system/dist/components/Flex";
import { BoxProps } from "@mui/material/Box";

interface Props {
    children?: ReactNode;
    className?: string;
    boxProps?: BoxProps;
    progressProps?: CircularProgressProps;
}

export default function CenterLoading({
    children,
    className,
    boxProps,
    progressProps,
}: Props) {
    return (
        <Flex
            column
            flex={"auto"}
            alignItems="center"
            justifyContent="center"
            className={className}
            {...boxProps}
        >
            <CircularProgress {...progressProps} />
            {children}
        </Flex>
    );
}
