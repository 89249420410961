import { Flex } from "@testboxlab/design-system/dist/components/Flex";
import { topBarHeight } from "~/components/frame/NavToolbar.styles";
import CenterLoading from "~/components/CenterLoading";

export default function BasicSuspenseLoading() {
    return (
        <Flex column>
            <CenterLoading
                className={"spec-suspense-loading"}
                boxProps={{ height: `calc(100vh - ${topBarHeight}px)` }}
                progressProps={{ id: "firstLoad" }}
            />
        </Flex>
    );
}
