import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import * as LDClient from "launchdarkly-js-client-sdk";

import { useAuth } from "~/state/auth";
import _ from "lodash";
import { User } from "~/hooks/useUser";
import clsx from "clsx";
import { useEnv } from "~/state/env";

const LD_KEY = "launchDarkly";

interface Props {
    children: ReactNode;
}

export interface LDFlags {
    hubspotIntegration?: boolean;
    enableEditor?: boolean;
    enableDatasetEditor?: boolean;
    enableQuickLinkEditor?: boolean;
    enableDemoScriptEditor?: boolean;
    enableNewDemoExperienceV1?: boolean;
    enableNewTestExperienceV1?: boolean;
    enableCustomerSubdomains?: boolean;
    enableCustomerSubdomainsCapture?: boolean;
    enableCustomerSubdomainsSandbox?: boolean;
    enableSandboxTutorial?: boolean;
    enableIntuitDemoFix?: boolean;
    hideDemoPanelFooter?: boolean;
    hideSandboxPanelFooter?: boolean;
    enableDemoControlTotp?: boolean;
    preventLeadsFetching?: boolean;
    enableHootsuiteCaptureTrialRenewal?: boolean;
    enableGoogleLoginWithoutEmailVerification?: boolean;
    sendIntuitToDemoRoute?: boolean;
    enableForcedDefaultRoute?: boolean;
    openDemoControlsInNewWindow?: boolean;
    redirectExpredMagicLinkToken?: boolean; // Yes, this is an unfortunate typo
    enablePresenterMode?: boolean;
    enableWalkthroughStepSkipping?: boolean;
    preventOldAppFrameInNewSandboxExperience?: boolean;
    demoScriptFeatureShortcuts?: boolean;
    fixDoubleIframing?: boolean;
    enableMultiProductInSandboxControls?: boolean;
    enableCapture?: string[];
    enableCaptureSunsetMessage?: boolean;
    showChromeExtensionAlertMessage?: boolean;
    enableSalespersonEmailNotifications?: boolean;
    enableActivityTracking?: boolean;
    useServerSideMixpanelTracking?: boolean;
    enableNoframing?: boolean;
    enableSellerSandboxLink?: boolean;
    enableUserSandboxQueryParameter?: boolean;
    enablePartnerManagement?: boolean;
    enableAlwaysShowNewUserMedia?: boolean;
    enableAccuratePurchasedLicensesCount?: boolean;
    enableArchiveDeals?: boolean;
    usePreAuthTropicBrevoModal?: boolean;
    enableReadOnlyPartnerDeals?: boolean;
    enableNoframingSandboxes?: boolean;
}

export const EDIT_FLAGS_MANUALLY =
    import.meta.env.VITE_MANUAL_LD_FLAGS === "true";

export function createDefaultsFlags(defaults: Partial<LDFlags>): LDFlags {
    return {
        hubspotIntegration: false,
        enableEditor: false,
        enableDatasetEditor: false,
        enableQuickLinkEditor: false,
        enableDemoScriptEditor: false,
        enableSandboxTutorial: false,
        enableIntuitDemoFix: false,
        hideDemoPanelFooter: false,
        hideSandboxPanelFooter: false,
        enableDemoControlTotp: false,
        preventLeadsFetching: false,
        sendIntuitToDemoRoute: false,
        openDemoControlsInNewWindow: false,
        enablePresenterMode: false,
        preventOldAppFrameInNewSandboxExperience: false,
        demoScriptFeatureShortcuts: false,
        fixDoubleIframing: false,
        enableCapture: [],
        showChromeExtensionAlertMessage: false,
        enableSalespersonEmailNotifications: false,
        enableActivityTracking: false,
        useServerSideMixpanelTracking: false,
        enableNoframing: false,
        enableSellerSandboxLink: false,
        enablePartnerManagement: false,
        enableAccuratePurchasedLicensesCount: false,
        enableArchiveDeals: false,
        usePreAuthTropicBrevoModal: false,
        enableReadOnlyPartnerDeals: false,
        enableNoframingSandboxes: false,
        enableAlwaysShowNewUserMedia: false,

        // Up for cleanup flags:
        enableNewDemoExperienceV1: true,
        enableNewTestExperienceV1: true,
        enableCustomerSubdomains: true,
        enableCustomerSubdomainsCapture: true,
        enableCustomerSubdomainsSandbox: true,
        enableHootsuiteCaptureTrialRenewal: false,
        enableGoogleLoginWithoutEmailVerification: true,
        enableForcedDefaultRoute: true,
        redirectExpredMagicLinkToken: true,
        enableWalkthroughStepSkipping: true,
        enableMultiProductInSandboxControls: true,
        enableCaptureSunsetMessage: true,
        enableUserSandboxQueryParameter: true,
        ...defaults,
    };
}

export const LaunchDarklyContext = createContext<{
    flags: LDFlags;
    ready: boolean;
    setFlags: (flags: LDFlags) => void;
}>({ flags: {}, ready: false, setFlags: () => {} });

export function useLaunchDarkly() {
    return useContext(LaunchDarklyContext);
}

export function useFlags() {
    const { flags, ready, setFlags } = useLaunchDarkly();
    return { flags, ready, setFlags };
}

const getLocalRecord = (key): Record<string, any> => {
    const record = localStorage.getItem(key) || "";
    try {
        return JSON.parse(record);
    } catch (e) {
        return { ready: false, flags: {} };
    }
};

function getEnableEditor(flags: LDFlags) {
    return _.some([
        flags.enableDatasetEditor,
        flags.enableQuickLinkEditor,
        flags.enableDemoScriptEditor,
    ]);
}

function getEnableIntuitDemoFix(originalFlags: LDFlags) {
    // We want sendIntuitToDemoRoute to supersede enableIntuitDemoFix.
    // Guarantee that when the new flag is true, the old one is false.
    if (originalFlags.sendIntuitToDemoRoute) {
        return false;
    }
    // sendIntuitToDemoRoute is false, so return whatever this was.
    return originalFlags.enableIntuitDemoFix;
}

function getLaunchDarklyUserContext(user?: User) {
    const isPartner =
        user?.current_workspace?.deal_salesperson_company_name !==
        user?.current_workspace?.company_name;
    return {
        kind: "user",
        key: user?.user_id || "not-logged-in",
        email: user?.email,
        // NOTE: may need to change lines below to user?.extra_info?.{first,last}Name
        firstName: user?.first_name,
        lastName: user?.last_name,
        anonymous: !user?.user_id,
        vendor: user?.current_workspace?.deal_salesperson_company_name,
        partneredCompany: isPartner
            ? user?.current_workspace?.company_name
            : null,
        salesperson_email: user?.current_workspace?.deal_salesperson_email,
    };
}

export function LaunchDarklyContextProvider({ children }: Props) {
    /* This context provider allows us to load our flags "on the side"
     *  and then update the flags whenever we get them
     *  It also lets us set the LAUNCH_DARKLY_FLAGS variable on the backend
     *  during local testing
     * */
    const env = useEnv();
    const { data: user, loading } = useAuth();

    const clientSideID = env?.LAUNCH_DARKLY_CLIENT_ID || "";
    const ldMockFlags = env?.LAUNCH_DARKLY_FLAGS || "";
    const [launchDarkly, setLaunchDarkly] = useState(getLocalRecord(LD_KEY));
    const client = useRef<LDClient.LDClient>();
    const isLocal =
        window.location.hostname.endsWith("local.testbox.com") ||
        // Brevo can't be iframed
        window.location.hostname.startsWith("testbox-local.brevo.com") ||
        window.location.hostname.startsWith("testbox-local.tropicapp.io");

    /*
     * TODO (EXP-973) - make the pattern we use to get a fake `enableEditor` flag more generalized
     */
    useEffect(() => {
        const existingFlags = getLocalRecord(LD_KEY);
        const onFlags = (flags) => {
            const camelFlags = _.mapKeys(flags, (v, k) => _.camelCase(k));
            const ldItem = {
                ready: true,
                flags: {
                    ...camelFlags,
                    enableEditor: getEnableEditor(camelFlags),
                    enableIntuitDemoFix: getEnableIntuitDemoFix(camelFlags),
                },
            };
            setLaunchDarkly(ldItem);
            localStorage.setItem(LD_KEY, JSON.stringify(ldItem));
        };

        if (existingFlags.ready && isLocal) {
            onFlags(createDefaultsFlags(existingFlags.flags));
        } else if (ldMockFlags && isLocal) {
            try {
                const jsonFlags = JSON.parse(ldMockFlags);
                onFlags(jsonFlags);
            } catch (e) {
                console.error(e);
            }
        } else if (clientSideID) {
            const context = getLaunchDarklyUserContext(user);
            client.current = LDClient.initialize(clientSideID, context);
            client.current.on("ready", () => {
                const allFlags = client.current?.allFlags();
                if (allFlags) {
                    onFlags(allFlags);
                }
            });
        } else if (env) {
            // have to wait on /env to ensure we are not preemptively
            // falling back to an empty state before LDMockFlags is checked
            setLaunchDarkly({ ready: true, flags: {} });
        }
    }, [clientSideID, loading, ldMockFlags, isLocal, user, env]);

    return (
        <LaunchDarklyContext.Provider
            value={{
                flags: launchDarkly.flags || {},
                ready: launchDarkly.ready,
                setFlags: (flags: LDFlags) => {
                    setLaunchDarkly({ ready: true, flags });
                    localStorage.setItem(
                        LD_KEY,
                        JSON.stringify({ ready: true, flags })
                    );
                },
            }}
        >
            <div
                className={clsx({
                    "spec-launch-darkly-ready": launchDarkly.ready,
                })}
                style={{ display: "none" }}
            />
            {children}
        </LaunchDarklyContext.Provider>
    );
}
